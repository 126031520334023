import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFi from '@angular/common/locales/fi';
import { LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AuthInterceptor} from './interceptors/http.interceptor';
import {from, Observable} from 'rxjs';
import {JwtModule} from '@auth0/angular-jwt';
import {environment} from '../environments/environment';

// Slack notification test
registerLocaleData(localeFi, 'fi');

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/lang/${lang}.json`));
  }
}

@NgModule({ 
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem('ng2-ui-auth_token');
        },
        allowedDomains: environment.allowedDomains,
        disallowedRoutes: [/no-token/],
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center'
    })
  ], 
  providers: [
    { provide: LOCALE_ID, useValue: 'fi' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})

export class AppModule {
  constructor(
      translate: TranslateService
  ) {
    translate.setDefaultLang('fi');
    const lang = localStorage.getItem('km2Language');
    if ( ! lang ) {
      const user = JSON.parse(localStorage.getItem('user'));
      if ( user && user.language ) {
        translate.use(user.language.code);
      } else {
        translate.use('fi');
      }
    } else {
      translate.use(lang);
    }
  }
}

