import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { BannerModel } from '../models/banner.model';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http: HttpClient) { }

  public banners: BannerModel[] = [];

  retrieve(): void {
    this.getActive().subscribe({
      next: (res) => {
        this.banners = res['data'];
      }
    })
  }

  getActive() {
    return this.http.get(`${environment.apiUrl}banners`);
  }
  
  getAll(params) {
    return this.http.get(`${environment.apiUrl}admin/banners`, params);
  }

  save(banner: BannerModel) {
    return this.http.post(`${environment.apiUrl}admin/banners`, banner);
  }

  update(id: number, banner: BannerModel) {
    return this.http.put(`${environment.apiUrl}admin/banners/${id}`, banner);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}admin/banners/${id}`);
  }

}
