import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  setPassword(data): Observable<any> {
    return this.http.post(environment.apiUrl + 'reset-password', data);
  }

  logout(): Observable<any> {
    return this.http.post(environment.apiUrl + 'logout' ,{});
  }

  login(user): Observable<any> {
    return this.http.post(environment.apiUrl + 'login', user);
  }

  setToken(token): void {
    localStorage.setItem('ng2-ui-auth_token', token);
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('ng2-ui-auth_token');
    return !!token;
  }

}
