export const environment = {
  production: false,
  envName: 'dev',
  kmUrl: 'https://km2dev.probreakerdev.com',
  apiUrl: 'https://api.km.dev.kiwa.dev/v2/',
  instaUrl: 'https://impact.dev.kiwa.dev',
  allowedDomains: ['api.km.dev.kiwa.dev'],
  store_id: 1,
  store_name: 'Koulutusmaailma',
  tinyMCEApiKey: 'lhu8rgyop1jcigmfttczz23wmqjieu9oloa6oorjn1i0t01i',
  sentryEnv: 'development',
  sentryApiUrl: 'https://c8f0bab8614a643c7aef7ec40977b8fd@sentry.kiwa.dev:9000/7'
};
