import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { BannerService } from './services/banner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private userService: UserService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private authService: AuthService,
    private bannerService: BannerService
  ) {}

  ngOnInit() {

    this.angulartics2GoogleTagManager.startTracking();

    // reset courses filters on startup
    localStorage.setItem('km2Filters', JSON.stringify({}));

    if (this.authService.isAuthenticated()) {
      this.userService.authUser = JSON.parse(localStorage.getItem('user'));
      this.userService.getMe().subscribe((res: any) => {
        this.userService.authUser = res.data;
        localStorage.setItem('user', JSON.stringify(res));
      }, (error) => {
        console.error(error);
      });
    }
    this.bannerService.retrieve();
  }
}
